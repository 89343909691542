<template>
  <div id="FamilyIndex">
    <!-- 查询 -->
    <div class="search-input-div">
      <van-search class="search-input" v-model="query.stuInfo" show-action placeholder="请输入关键词查询" @search="toQuery">
        <template #action>
          <div @click="toQuery">搜索</div>
        </template>
      </van-search>
    </div>
    <!-- 卡片 -->
    <van-list v-model="loading" :immediate-check="false" offset="30" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-cell-group v-else inset style="margin-top:15px" v-for="(row,index) in data" :key="index">
        <van-cell>
          <template #title>
            <span class="custom-title hidden-text"> <span style="color:rgba(35, 171, 114, 1)">{{row.xn +'年' +codeConvertLabel(row.xq,dict.xj_xqgl)}}</span></span>
          </template>
          <template #label>
            <div class="custom-centent margin6 hidden-text95">
              <span @click="toStuDeatil(row)"> <b style="font-size:15px;color:black">{{row.stuName}}</b> {{  row.stuNo}}</span>
              <!-- <span class="v-title-icon" @click="toDetail(row)">
                <van-icon name="arrow" /></span> -->
            </div>
            <div class="custom-centent margin6 hidden-text95">
              <svg-icon icon-class="date" size="15" /> 分类: {{ row.type1.name}}
            </div>
            <div class="custom-centent margin6 hidden-text95">
              <svg-icon icon-class="date" size="15" /> 时间: {{parseTime(row.createTime, "{y}-{m}-{d}")}}
            </div>
            <div class="custom-centent margin6 hidden-text95">
              <van-icon name="user-o" /> 说明: {{ row.explain }}
            </div>
          </template>
          <template #right-icon>
            <span v-if="row.approvalStatus === 0 " class="audit info-color b-color-info">待提交</span>
            <span v-else-if="statustag(row.approvalStatus,'success')" class="audit success-color b-color-success">审核通过</span>
            <span v-else-if="statustag(row.approvalStatus,'info')" class="audit info-color b-color-info">待审核</span>
            <span v-else-if="statustag(row.approvalStatus,'error')" class="audit error-color b-color-error">审核不通过</span>
            <span v-else-if="statustag(row.approvalStatus,'warning')" class="audit warning-color b-color-warning">审核驳回</span>
            <div class="ckeckbox-st" @click="checkHandel(row)">
              <van-checkbox v-model="row.checked" icon-size="21px" shape="square"></van-checkbox>
            </div>
          </template>
        </van-cell>
        <van-cell id="opt-style">
          <template #title>
            <span v-if="statustag(row.approvalStatus,'success')" class="success-color hidden-text95">
              <div class="status-tag ok-tag"></div>{{getSpztLabel(row.approvalStatus)}}
            </span>
            <span v-else-if="statustag(row.approvalStatus,'info')" class="info-color hidden-text95">
              <div class="status-tag info-tag"></div>{{getSpztLabel(row.approvalStatus)}}
            </span>
            <span v-else-if="statustag(row.approvalStatus,'error')" class="error-color hidden-text95 ">
              <div class="status-tag error-tag"></div>{{getSpztLabel(row.approvalStatus)}}
            </span>
            <span v-else-if="statustag(row.approvalStatus,'warning')" class="warning-color hidden-text95 ">
              <div class="status-tag warning-tag"></div>{{getSpztLabel(row.approvalStatus)}}
            </span>
          </template>
          <template #right-icon>
            <van-button class="table-edit-btn" @click="toDetail(row)" plain round size="small" type="primary">详情</van-button>
            <!-- v-if="row.approvalStatus !== 9" -->
            <!-- <van-button class="table-edit-btn" v-else @click="toCheckScore(row)" :disabled="row.scoreCheckInfos.length  !== 0" plain round size="small" type="primary">打分</van-button> -->
          </template>
        </van-cell>
      </van-cell-group>
    </van-list>
    <div class="batch-button">
      <van-row>
        <van-col span="5">
          <van-checkbox class="batch-checked" @click="selcetAll" v-model="checkedAll" checked-color="#23AB72">全选</van-checkbox>
        </van-col>
        <van-col span="9">
          <div class="batch-checked-text" v-html="selectionText"></div>
        </van-col>
        <van-col span="10">
          <van-button round block type="primary" color="#23AB72" :disabled="this.selections.length ===0" @click="batchAudit()">批量审批</van-button>
        </van-col>
      </van-row>
    </div>
    <van-dialog v-model="showAudit" title="审批" :showConfirmButton="false" width="90%">
      <div @click="toCloseAudit(false)" class="cancel">取消</div>
      <van-form ref="form">
        <van-field v-model="audit.appendData.opinion" :border="true" rows="3" label="审核意见" type="textarea" maxlength="50" placeholder="请输入审核意见" show-word-limit :rules="[{ required: true, message: '请输入审核意见' }]" />
      </van-form>
      <van-row style="padding:10px 10px 20px 10px" justify="center">
        <van-col span="12" align="center">
          <van-button type="danger" :loading="auditLoading2" :disabled="auditDisabled2" round style="width:80%" @click="confirmAudit(9)">拒绝</van-button>
        </van-col>
        <van-col span="12" align="center">
          <van-button type="primary" :loading="auditLoading3" :disabled="auditDisabled3" round style="width:80%" @click="confirmAudit(1)">同意</van-button>
        </van-col>
      </van-row>
    </van-dialog>
    <switch-role @change="toQuery()"></switch-role>
  </div>
</template>

<script>
import initData from "@/mixins/initData";
import crudEvaluationActivity from '@/api/modules/evaluation/evaluationActivity'
import batchAudit from "@/mixins/batchAudit";


export default {
  name: "EvaluationActivity",
  mixins: [initData, batchAudit],
  dicts: ['evaluation_activity_type', 'xj_nj', 'xj_xqgl', 'xj_shzt'],
  data() {
    return {
      showYearPicker: false,
      defaultIndex: 1,
      searchTetx: "",
      options: [],
      audit: {
        appendData: {
          opinion: "同意",
          like: [],
          field3: null,
          field1: null,
        },
        coreKey: "",
        type: "ZCGL",
        callback: "api/evaluation/addScore/approval",
        mkCode: "ZHCP",
        coreCode: "ATTENDANCE",
        status: 9,
      },
    };
  },
  created() { },
  watch: {
    "dict.xj_nj": {
      deep: false,
      handler(newval, oldval) {
        if (newval.length > 0) {
          this.options = this.convertData(newval);
        }
      },
    },
  },
  methods: {
    isDateTimeInRange(startDateTime, endDateTime) {
      var now = new Date();
      var startTime = new Date(startDateTime);
      var endTime = new Date(endDateTime);

      if (now < startTime) {
        return "未开始";
      } else if (now > endTime) {
        return "已结束";
      } else {
        return "进行中";
      }
    },
    beforeInit() {
      this.url = "/api/evaluation/addScore";
      this.sort = ['approvalStatus,asc', 'createTime,desc', 'facultyNo,asc', 'specNo,asc', 'classNo,asc', 'stuName,asc'];
      this.query.type = 1
      return true;
    },
    doAdd() {
      this.$router.push(
        { path: "/js/addSubtract/form" }
      );
    },
    toDetail(data) {
      this.$router.push({
        path: "/js/addSubtract/detail",
        query: { id: data.id, status: data.approvalStatus },
      });
    },
    async confirmAudit(type) {
      let roles = this.user.roles.map((item) => {
        return item.id
      })
      let value = []
      let user = this.user

      if (roles.indexOf(4) > -1) {
        value = this.selections.filter((item) => {
          return ['2'].indexOf(item.approvalStatus + '') > -1
        })
        this.audit.appendData.field3 = 2
      }
      if (roles.indexOf(11) > -1) {
        value = this.selections.filter((item) => {
          return ['7'].indexOf(item.approvalStatus + '') > -1 && item.shr === user.id + ""
        })
        this.audit.appendData.field3 = 7
      }
      this.audit.status = type
      if (value.length > 0) {
        await this.$refs.form.validate().then(
          (res) => {
            this.loadingStatus(type)
            this.selections.forEach((element) => {
              this.audit.coreKey = element.id;
              this.auditApi(this.audit).then((resp) => {
                if (resp.success) {
                  this.toCloseAudit(true);
                  this.resetField();
                }
              });
            });

          },
          (err) => { }
        );
        this.toQuery()
      } else {
        this.$notify({
          type: "success",
          message: "请选择需要自己审核的数据",
          duration: 2000,
        });
      }
    },
    // 编辑
    toEdit(data) {
      this.$router.push({
        path: "/js/addSubtract/form",
        query: { id: data.id },
      });
    },
    //打分
    toCheckScore(data) {
      this.$router.push({
        path: "/xs/attendance/check/form",
        query: { id: data.id },
      });
    },
    // 删除操作
    toDel(data) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "你确定要删除该条数据吗？",
        })
        .then(() => {
          this.$set(data, "delLoading", true);
          crudEvaluationActivity.del([data.id])
            .then((res) => {
              this.$notify({
                type: "success",
                message: "操作成功",
                duration: 2000,
              });
              this.init();
              this.$set(data, "delLoading", false);
            })
            .catch((e) => {
              this.$set(data, "delLoading", false);
            });
        })
        .catch(() => {
          this.$set(data, "delLoading", false);
        });
    },
    onConfirm(value) {
      this.showYearPicker = false;
      this.query.year = value.label;
      this.toQuery();
    },
    convertData(dict) {
      let options = [];
      dict.forEach((item) => {
        let data = { text: item.label + "年", value: item.value };
        options.push(data);
      });
      if (options) {
        this.query.year = new Date().getFullYear().toString();
      }
      return options;
    },
    changeYear(val) {
      this.$nextTick((e) => {
        this.query.year = val;
      });
      this.toQuery();
    },
    cleanSearch() {
      this.searchTetx = "";
      this.query = {};
      this.toQuery();
    },
    openShowHandler() {
      // 默认选中数据
      this.dict.xj_nj.forEach((item, index) => {
        if (this.query.year) {
          if (this.query.year === item.value) {
            this.defaultIndex = index;
          }
        } else if (new Date().getFullYear().toString() === item.value) {
          this.defaultIndex = index;
        }
      });
      this.showYearPicker = true;
    },
    stuDisableBtn(spzt, type) {
      let status = parseInt(spzt);
      if ([9].indexOf(status) > -1) {
        return true;
      }
      if ([101, 201, 301, 3, 7, 701,].indexOf(status) > -1) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang='scss' scoped>
.van-list {
  margin-bottom: 54px;
}
.van-cell__title {
  font-size: 16px;
  text-align: left;
  min-width: 70%;
}
::v-deep .van-dropdown-menu__bar {
  height: 44px;
  box-shadow: 0 0px 0px rgb(100 101 102 / 12%);
}
.detail-pos {
  position: absolute;
  top: 50%;
  right: 15px;
}
#opt-style {
  .van-cell__title {
    min-width: 50%;
    font-size: 14px;
    line-height: 32px;
  }
}
.ckeckbox-st {
  font-size: 21px;
  top: 50%;
  position: absolute;
  right: 5%;
}
.detail-st {
  top: 50%;
  position: absolute;
  right: 5%;
}
.cancel {
  color: #999999;
  position: absolute;
  top: 20px;
  right: 30px;
}
</style>
